import React from "react";
import Project from "./Project";
import WorkHistory from "./WorkHistory";

type Props = {};

const Work = (props: Props) => {
	const projects = [
		{
			title: "First React Project: Online Checkers Game and AI Opponent",
			link: "www.linkgoeshere.com",
			description:
				"My first (very rough) attempt at using React. A standard, two-player checkers game with an optional AI opponent.",
		},
	];

	const workHistory = [
		{
			company: "Capgemini",
			positions: [
				{
					location: "Remote",
					duration: "September 2021 - Present",
					position: "Software Engineer",
					description: [
						"Completed Java, JavaScript and React training courses alongside client-facing collaboration training",
						"Developed internal applications using React, including a training course management system",
						"Continued to pursue outside education, including passing the Java SE 8 Programmer I exam",
					],
				},
			],
		},
		{
			company: "Cornell University",
			positions: [
				{
					location: "Ithaca, NY",
					duration: "September 2017 - May 2021",
					position: "Information Specialist",
					description: [
						"Provided customer service and sold parking permits to campus visitors as concierge at the campus transportation booth",
						"Worked in call center queue answering inquiries to the University",
						"Conducted in-depth tours of Cornell University's undergraduate schools and colleges for visiting potential students considering enrolling in the University",
					],
				},
			],
		},
		{
			company: "McDonogh School",
			positions: [
				{
					location: "Owings Mills, MD",
					duration: "June 2017 - August 2017",
					position: "Software Development Intern",
					description: [
						"Responsible for managing a back-end MySQL database supporting a ColdFusion website",
						"Created and maintained front-end webpages using JavaScript, HTML, and CSS",
					],
				},
				{
					location: "Owings Mills, MD",
					duration: "June 2015 - August 2016",
					position: "Hardware and Support Intern",
					description: [
						"Installed networking systems across campus",
						"Provided IT support to teachers and camp counselor",
					],
				},
			],
		},
	];

	return (
		<div className="p-10 text-2xl mx-auto max-w-full sm:max-w-xl md:max-w-2xl lg:max-w-3xl">
			<article className="space-y-14">
				{/* <div>
					<h3 className="tracking-wide font-bold">PROJECTS</h3>
					{projects.map((p) => (
						<Project data={p} />
					))}
				</div> */}
				<div>
					<h3 className="tracking-wide font-bold text-center">
						WORK HISTORY
					</h3>
					{workHistory.map((w) => (
						<WorkHistory data={w} />
					))}
				</div>
				<div>
					<h3 className="tracking-wide font-bold text-center mb-5">
						CERTIFICATIONS
					</h3>
					<ul className="text-lg list-disc px-7">
						<li>Java SE 8 Programmer I</li>
					</ul>
				</div>
			</article>
		</div>
	);
};

export default Work;
