import React, { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import "./navbar.css";

type Props = {};

const Navbar = () => {
	const [isOpen, setIsOpen] = useState(false);

	const toggleMobileMenu = () => {
		setIsOpen(!isOpen);
	};

	const hide = () => setIsOpen(false);

	var prevScrollpos = window.pageYOffset;

	window.onscroll = function () {
		if (!isOpen) {
			var currentScrollPos = window.pageYOffset;
			const navbar = document.getElementById("navbar");
			if (navbar) {
				if (prevScrollpos > currentScrollPos) {
					navbar.style.top = "0";
				} else {
					navbar.style.top = "-50px";
				}
				prevScrollpos = currentScrollPos;
			}
		}
	};

	return (
		<nav
			className="bg-blue-300 bg-[#223843] w-full sticky top-0 z-10"
			id="navbar"
		>
			<div className="relative items-center h-12 sm:h-auto">
				<div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
					<button
						type="button"
						className="inline-flex items-center justify-center p-2 rounded-md text-white hover:underline focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
						aria-controls="mobile-menu"
						aria-expanded="false"
						onClick={toggleMobileMenu}
					>
						<span className="sr-only">Open main menu</span>
						<svg
							className="block h-6 w-6"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth="2"
							stroke="currentColor"
							aria-hidden="true"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M4 6h16M4 12h16M4 18h16"
							/>
						</svg>
						<svg
							className="hidden h-6 w-6"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth="2"
							stroke="currentColor"
							aria-hidden="true"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M6 18L18 6M6 6l12 12"
							/>
						</svg>
					</button>
				</div>
				<div className="text-center text-white w-full space-x-10 p-2 hidden sm:block">
					<NavLink
						to="/"
						className={({ isActive }) =>
							isActive ? "underline" : "hover:underline"
						}
					>
						HOME
					</NavLink>
					<NavLink
						to="about"
						className={({ isActive }) =>
							isActive ? "underline" : "hover:underline"
						}
					>
						ABOUT
					</NavLink>
					<NavLink
						to="work"
						className={({ isActive }) =>
							isActive ? "underline" : "hover:underline"
						}
					>
						WORK
					</NavLink>
				</div>
			</div>
			<div
				className="sm:hidden"
				id="mobile-menu"
				hidden={!isOpen}
				onBlur={hide}
			>
				<div className="px-2 pt-2 pb-3 space-y-1 flex flex-col text-center text-white">
					<NavLink
						to="/"
						className={({ isActive }) =>
							isActive ? "underline" : "hover:underline"
						}
						onClick={hide}
					>
						HOME
					</NavLink>
					<NavLink
						to="about"
						className={({ isActive }) =>
							isActive ? "underline" : "hover:underline"
						}
						onClick={hide}
					>
						ABOUT
					</NavLink>
					<NavLink
						to="work"
						className={({ isActive }) =>
							isActive ? "underline" : "hover:underline"
						}
						onClick={hide}
					>
						WORK
					</NavLink>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
