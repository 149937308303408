import React from "react";

type Props = {};

const Contact = (props: Props) => {
	return (
		<div className="bg-theme-green opacity-80 bg-border-solid border-2 border-black rounded-xl m-auto p-4 md:p-7 text-md sm:text-lg text-center space-y-7 md:max-w-3xl mt-10 overflow-auto">
			<address>
				<table className="mx-auto border-separate border-spacing-y-5 border-spacing-x-2 md:border-spacing-x-7">
					<tbody>
						<tr>
							{/* <th className="sm:w-1/4">Email</th> */}
							<td>
								<a
									className="underline"
									href="mailto: jmcdonald16@gmail.com"
								>
									jmcdonald16@gmail.com
								</a>
							</td>
						</tr>
						<tr>
							{/* <th>LinkedIn</th> */}
							<td>
								<a
									target="_blank"
									className="underline"
									href="https://www.linkedin.com/in/james-mcdonald-sde/"
								>
									https://www.linkedin.com/in/james-mcdonald-sde/
								</a>
							</td>
						</tr>
						<tr>
							{/* <th>GitHub</th> */}
							<td>
								<a
									target="_blank"
									className="underline"
									href="https://github.com/jmcdonald16"
								>
									https://github.com/jmcdonald16
								</a>
							</td>
						</tr>
					</tbody>
				</table>
			</address>
		</div>
	);
};

export default Contact;
