import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Work from "./components/Work/Work";

function App() {
	useEffect(() => {
		document.title = "James McDonald";
	}, []);
	return (
		<div className="flex flex-col min-h-screen bg-[#D1D5DB] w-full">
			<Navbar></Navbar>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/about" element={<About />} />
				<Route path="/contact" element={<Contact />} />
				<Route path="/work" element={<Work />} />
			</Routes>
		</div>
	);
}

export default App;
