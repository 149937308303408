import React from "react";
import DogCarousel from "./DogCarousel/DogCarousel";

type Props = {};

const About = (props: Props) => {
	return (
		<div className="p-10 text-lg mx-auto max-w-full sm:max-w-xl md:max-w-2xl lg:max-w-3xl sm:text-justify">
			<article className="space-y-7 mb-14">
				<p>
					Hi! My name is James McDonald. I'm a full-stack web
					developer from Charlotte, North Carolina.
				</p>
				<p>
					My programming journey began the summer before I started
					high school. I attended a one week computer science camp at
					the University of Virginia where we were taught the absolute
					basics of programming through Java. The camp culminated in a
					competition: we had to form teams of three and program a
					lego robot with an expo marker on its underside to draw a
					picture by driving over a whiteboard. My team tried to make
					our robot draw a tree, but as you can probably imagine, we
					failed spectacularly. The robot spun the entire time it drew
					our rough tree outline, leaving us with something that
					looked more like a grape vine. We spent hours debugging,
					testing, and even rewriting our code until we finally had
					the tree we imagined. Except, after looking at the tree, we
					decided we actually liked the grape vine design more. So, we
					reverted our code back to the first version, and ended up
					winning third place in the competition. I thought it was so
					cool how we were able to recognize a bug, fix it together,
					but still end up incorporating the bug as a useful feature
					once we understood it. I left the camp with some new
					friends, my thrid place iPod Shuffle prize, and a newfound
					passion for programming.
				</p>
				<p>
					In high school, I jumped at the opportunity to join the
					robotics team as a programmer, alongside any and all offered
					computer science courses. The robotics team competed in
					FIRST's FRC league, and it was a blast. I loved the
					comraderie and pace. After experiencing the hardware and
					software teams come together to create an awesome robot, I
					could really see myself enjoying programming as a career. In
					2021, I graduated from Cornell University's College of
					Engineering with a degree in Computer Science, and I have
					been happily living out my vision ever since.
				</p>
				{/* <p>
					I graduated from Cornell University's College of Engineering
					in 2021 with a degree in Computer Science. Before that, I
					completed high school McDonogh School in Owings Mills,
					Maryland. McDonogh is where I first discovered my passion
					for programming. Although I had dabbled with Scratch and the
					most basic Java in the past, it was McDonogh's robotics team
					that gave me my first opportunity to program with purpose. I
					loved problem solving, collaborating, and showing off the
					finished product our team put so much work into. The CS
					courses I completed after my first season on the robotics
					team ticked all of the same boxes, and just like that, a
					nerd was born.
				</p> */}
				<p>
					As a web-developer, React is my front-end library of choice.
					On the back-end side, I have experience using Express,
					Next.js, and Spring Boot. Recently, I've been using Tailwind
					CSS for all of my styling needs (including this website),
					but I've used Bootstrap, Chakra UI, and of course plain CSS
					in the past.
				</p>
				<p>
					Besides programming, I like to spend my time working out,
					playing video games, or hanging out with my amazing dogs
					Rocko and Rosie (pictured below).
				</p>
			</article>

			<div>
				<DogCarousel />
			</div>
		</div>
	);
};

export default About;
