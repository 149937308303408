import React from "react";
import Contact from "./Contact";

type Props = {};

const Home = (props: Props) => {
	return (
		<div className="flex flex-col h-full mt-12 p-5 max-w-full">
			<h1 className="text-4xl sm:text-5xl md:text-6xl text-center tracking-widest">
				JAMES MCDONALD
			</h1>
			<h2 className="text-xl text-center pt-3">
				FULL-STACK WEB DEVELOPER
			</h2>
			<div className="mx-auto mt-14 rounded-full w-5/6 md:w-1/2 lg:w-1/3 xl:w-1/4">
				<img
					className="object-contain rounded-2xl"
					src="https://jmcdonald-portfolio-bucket.s3.amazonaws.com/IMG_0300_edited.jpg"
				></img>
			</div>
			<div className="p-3">
				<Contact />
			</div>
			{/* <div className="mt-14 p-8 max-w-sm sm:max-w-xl md:max-w-2xl lg:max-w-none rounded-lg mx-auto text-center flex flex-col text-xl space-y-5 bg-orange-300 border-2 border-black border-solid">
				<p>
					Welcome! I'm James, a full-stack web developer from
					Charlotte, North Carolina. Thank you for visiting my
					portfolio.
				</p>
				<p>This website was built using React, TailwindCSS, and AWS.</p>
			</div> */}
		</div>
	);
};

export default Home;
