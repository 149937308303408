import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import pic1 from "./32FDD71A-FE02-4601-AA39-5D44165A13F6_1_105_c.jpeg";
import pic2 from "./41AF078A-8420-4CB1-89C6-CDA2E014326D_1_105_c.jpeg";
import pic3 from "./B61ABCE0-1EA5-461D-89AB-AF40C722EF65_1_105_c.jpeg";
import pic4 from "./DCDBBA12-8C5C-47F4-A5AE-516F259D2E62_1_105_c.jpeg";

type Props = {};

const DogCarousel = (props: Props) => {
	const settings = {
		arrows: false,
		dots: true,
		infinite: true,
		autoplay: true,
		adaptiveHeight: true,
	};

	return (
		<div className="bg-theme-green p-3 pb-7 rounded-lg mx-auto sm:w-2/3">
			<Slider {...settings}>
				<div>
					<img className="object-scale-down" src={pic1} />
				</div>
				{/* <div>
					<img className="object-scale-down" src={pic2} />
				</div> */}
				<div>
					<img className="object-scale-down" src={pic3} />
				</div>
				<div>
					<img className="object-scale-down" src={pic4} />
				</div>
			</Slider>
		</div>
	);
};

export default DogCarousel;
