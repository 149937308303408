import React from "react";

type PositionData = {
	location: string;
	duration: string;
	position: string;
	description: string[];
};

type CompanyData = {
	company: string;
	positions: PositionData[];
};

type Props = {
	data: CompanyData;
};

const WorkHistory = (props: Props) => {
	return (
		<div className="mt-5 mb-7">
			<p className="font-bold text-lg underline">
				{props.data.company.toUpperCase()}
			</p>
			{props.data.positions.map((p, i) => {
				return (
					<div className="mt-2 text-base">
						<p className="font-bold">{p.position}</p>
						{/* <p className="">{p.location + " | " + p.duration}</p> */}
						<p className="">{p.location}</p>
						<ul className="list-disc max-w-md md:max-w-2xl mb-2 pl-7">
							{p.description.map((d) => (
								<li>{d}</li>
							))}
						</ul>
					</div>
				);
			})}
		</div>
	);
};

export default WorkHistory;
